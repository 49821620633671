<template>
  <main class="main">
    <nav aria-label="breadcrumb" class="breadcrumb-nav mb-0 border-0">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">{{ $t("home") }}</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link to="/merken/">{{ $t("brands") }}</router-link>
          </li>
          <li class="breadcrumb-item active">{{ pageTitle }}</li>
          <li class="breadcrumb-item" v-if="$route.query.searchTerm">
            <span>Search - {{ $route.query.searchTerm }}</span>
          </li>
        </ol>
        <!-- <h4>Alcatel</h4> -->
        <h4>{{ brandDetail.title }}</h4>
      </div>
    </nav>

    <div class="page-content">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-9 skeleton-body skel-shop-products"
            :class="{ loaded: loaded }"
          >
            <div class="row flex-column-reverse flex-md-row">
              <div class="col-md-9 category-text-seo">
                <h4>{{ brandDetail.title }}</h4>
                <read-more
                  v-if="brandDetail.shortDescription"
                  :text="brandDetail.shortDescription"
                  :max-chars="81"
                ></read-more>
              </div>
              <div class="col-md-3 d-flex justify-content-end">
                <img
                  :src="
                    brandDetail
                      ? brandDetail.image
                      : '@/esf_weert_mobilesupplies/assets/static/images/products/iphone_14.png'
                  "
                  alt="brand"
                  class="img-fluid"
                />
              </div>
            </div>

            <p class="no-results" v-if="brandProducts.length === 0">
              No products matching your selection.
            </p>

            <div v-else>
              <pagination-comp
                :per-page="perPage"
                :total="totalCount"
                :layout-type="layout"
                :sortBy="sortBy"
                @handleLayout="handleLayout"
                @handlePerPage="handlePerPage"
                @handleSortBy="handleSortBy"
              />

              <div class="cat-blocks-container">
                <template v-if="type !== 'list'">
                  <div class="row">
                    <div
                      class="col-6 col-md-4 col-lg-4 m-2 mx-auto"
                      v-for="product in brandProducts"
                      :key="product.url_key"
                    >
                      <product-home
                        :product="product"
                        :nameMaxLength="15"
                      ></product-home>
                    </div>
                  </div>
                </template>

                <template v-if="type === 'list'">
                  <div
                    class="row"
                    v-for="product in brandProducts"
                    :key="product.url_key"
                  >
                    <div class="col-12">
                      <product-home-list :product="product"></product-home-list>
                    </div>
                  </div>
                </template>
              </div>

              <pagination-comp
                :per-page="perPage"
                :total="totalCount"
                :layout-type="layout"
                :sortBy="sortBy"
                @handleLayout="handleLayout"
                @handlePerPage="handlePerPage"
                @handleSortBy="handleSortBy"
              />

              <div class="row">
                <div class="col-md-12 category-text-seo">
                  <h5>{{ brandDetail.title }}</h5>
                  <Read-more
                    v-if="brandDetail.description"
                    :text="brandDetail.description"
                    :maxChars="81"
                  ></Read-more>
                </div>
              </div>
            </div>
          </div>

          <!-- sticky-container -->
          <aside class="col-lg-3 order-lg-first">
            <!-- v-sticky="!isSidebar" sticky-offset="{ top: 69 }" -->
            <div v-if="aggregation && aggregation.length > 0">
              <button
                class="sidebar-fixed-toggler"
                @click="toggleSidebar"
                v-if="isSidebar"
              >
                <i class="icon-cog"></i>
              </button>

              <div class="sidebar-filter-overlay" @click="hideSidebar"></div>
              <shop-sidebar-one
                :is-sidebar="isSidebar"
                :aggregation="this.aggregations"
              ></shop-sidebar-one>
            </div>
          </aside>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
// import Sticky from 'vue-sticky-directive';
import ShopSidebarOne from "@/esf_weert_mobilesupplies/core/components/partial/shop/sidebar/ShopSidebarOne";
import PaginationComp from "@/esf_weert_mobilesupplies/core/components/elements/PaginationComp";
import ReadMore from "@/esf_weert_mobilesupplies/core/components/elements/ReadMore.vue";
import ProductHome from "@/esf_weert_mobilesupplies/core/components/elements/products/ProductHome";
import ProductHomeList from "@/esf_weert_mobilesupplies/core/components/elements/products/ProductHomeList";
// import { homeData } from '@/esf_weert_mobilesupplies/utilities/data';

// import Repository, { baseUrl } from '@/repositories/repository.js';
// import { scrollToPageContent } from '@/utilities/common';

export default {
  components: {
    ShopSidebarOne,
    PaginationComp,
    ProductHome,
    ProductHomeList,
    ReadMore,
  },
  // directives: {
  //     Sticky
  // },
  data: function () {
    return {
      products: [],
      pageTitle: "",
      isSidebar: true,
      loaded: false,
      seoMsg:
        "Het is heel simpel en snel online telefoon accessoires zoeken bij Mobile Supplies. Je vindt gemakkelijk in meer dan 10.000 producten jouw gewenste mobiele telefoon accessoires. Ook als je smartphone stuk is kun je hier bij reparatie onderdelen de juiste onderdelen vinden. Maar als je een leuk hoesje zoekt of gewoon je autolader weer eens kwijt bent vind je bij Mobile Supplies zeker een goede!",
      // perPage: 5,
      // defaultType: 'cols',
      // type: 'list',
      // totalCount: 10,
      // orderBy: 'default',

      // pagination
      aggregation: null,
      perPage: 24,
      layout: "cols",
      totalCount: 0,
      sortBy: "price_low_high",
      isLoading: true,
      // pagination
    };
  },
  computed: {
    ...mapGetters({
      aggregations: "product/getAggregations",
    }),
    brandDetail: function () {
      return this.$store.getters["product/getBrandDetailData"];
    },
    brandProducts: function () {
      return this.$store.getters["product/getBrandProducts"];
    },
    // pagination
    type: function () {
      return this.layout;
    },
    currentPage: function () {
      return parseInt(this.$route.query.page ? this.$route.query.page : 1);
    },
    paginatedData() {
      if (!this.products) return [];

      let start = (this.currentPage - 1) * this.perPage;
      let end = start + this.perPage;

      // Apply sorting based on sortBy
      let sortedCategories = [...this.products];
      if (this.sortBy === "price_low_high") {
        sortedCategories.sort((a, b) => a.price - b.price);
      } else if (this.sortBy === "price_high_low") {
        sortedCategories.sort((a, b) => b.price - a.price);
      }
      // Add more sorting logic if needed

      return sortedCategories.slice(start, end);
    },
    // pagination

    // gridClass: function () {
    //     if (this.type === 'list') return 'col-12';
    //     if (this.type === '3cols') return 'col-6 col-md-4 col-lg-4';
    //     if (this.type === 'cols')
    //         return 'col-6 col-md-4 col-lg-4 col-xl-3';
    //     else
    //         return 'col-6 col-md-4 col-lg-4 col-xl-3';
    // },
    // fakeArray: function () {
    //     let temp = [];
    //     for (let i = 0; i < this.perPage; i++) {
    //         temp.push(i);
    //     }
    //     return temp;
    // },

    // currentbrand() {
    //     return this.$store.getters["product/getBrandSliderByCode"](
    //         this.$route.params.code
    //     );
    // },
  },
  watch: {
    $route: function () {
      this.fetchBrand();
    },
    brandProducts(newProducts) {
      this.totalCount = newProducts.length;
    },
  },
  // created: function () {
  //     this.getProducts();
  // },
  mounted: function () {
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler, {
      passive: true,
    });
    this.fetchBrand();
  },
  unmounted: function () {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    async fetchBrand() {
      console.log("this.$route", this.$route);
      const key = this.$route.params.code;
      const retval = await this.$store.dispatch("product/getBrandDetail", {
        urlKey: key,
      });
      // Get products
      if (retval) {
        const p = await this.$store.dispatch("product/getProductByBrand");
        console.log("p", p);
        this.isLoading = false;
      } else {
        console.error("Error fetching brand detail");
      }
    },
    // getProducts: async function (samePage = false) {
    //     console.log(samePage);
    //     this.type = this.layout;
    //     this.products = await homeData.products;
    //     if (this.type == 'list') {
    //         this.pageTitle = 'Brand Details';
    //         // this.perPage = 2;
    //     } else if (this.type == 'cols') {
    //         this.pageTitle = 'Brand Details';
    //         // this.perPage = 5;
    //     }
    //     this.loaded = true;
    //     // await Repository.get(`${baseUrl}/shop`, {
    //     //     params: {
    //     //         ...this.$route.query,
    //     //         orderBy: this.orderBy,
    //     //         perPage: this.perPage,
    //     //         demo: this.currentDemo
    //     //     }
    //     // })
    //     //     .then(response => {
    //     //         this.shop = response.data.products;
    //     //         this.totalCount = response.data.totalCount;
    //     //         this.loaded = true;

    //     //         if (samePage) {
    //     //             scrollToPageContent();
    //     //         }
    //     //     })
    //     //     .catch(error => ({ error: JSON.stringify(error) }));
    // },
    toggleSidebar: function () {
      if (
        document
          .querySelector("body")
          .classList.contains("sidebar-filter-active")
      ) {
        document
          .querySelector("body")
          .classList.remove("sidebar-filter-active");
      } else {
        document.querySelector("body").classList.add("sidebar-filter-active");
      }
    },
    hideSidebar: function () {
      document.querySelector("body").classList.remove("sidebar-filter-active");
    },
    resizeHandler: function () {
      if (window.innerWidth > 991) this.isSidebar = false;
      else this.isSidebar = true;
    },
    // pagination
    handleLayout: function (layout) {
      this.layout = layout;
    },
    handlePerPage: function (count) {
      this.perPage = count;
    },
    handleSortBy: function (sort) {
      this.sortBy = sort;
    },
    // pagination
  },
};
</script>
